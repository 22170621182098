import * as moment from 'moment';
import { HEATPUMP_IDS, OBJECTSTROM_PRODUCT_ID, REGIO_IDS } from './app.consts';
import { MbsPriceInformationDtoInterface, SectorType  } from '@ekso/ekso-types';
import { ExpensesDTO, MbsProductHttp, PriceFullIfc, ProductBonus, runtimeInMonths } from './mbsProduct.interface';
import { HardcodedBonuses, NEW_CUSTOMER_BONUS } from './mbsProduct.const';

export class MbsProduct {
  public apiIfc: MbsPriceInformationDtoInterface[]; // MbsProductHttp;
  public expenses: ExpensesDTO;
  public runtimesInMonths: runtimeInMonths[] = [];
  public name: string[] = [];
  public id: number[] = [];
  public prices: PriceFullIfc[] = [];
  public features: Array<ProductBonus[]> = [];
  public priceGuarantee: number[] = [];
  public priceGuaranteeEnd: Date[] = [];
  public grundpreis: number[] = [];
  public grundpreisNet: number[] = [];
  public arbeitspreis: number[] = [];
  public netpreis: number[] = [];
  public totalCostPerMonthNet: number[] = [];
  public isSufficientInformationProvided: boolean;
  public activeRuntimeMonths: runtimeInMonths;
  public activeRuntimeIndex: number;
  public section: SectorType;
  City: string;

  static isPayloadAProduct(ifc: MbsProductHttp) {
    return (
      ifc.priceInformation &&
      Array.isArray(ifc.priceInformation) &&
      ifc.priceInformation.length > 0
    );
  }

  static areProductsSame(p1: MbsProduct, p2: MbsProduct): boolean {
    return (
      p1.getActiveRuntime() === p2.getActiveRuntime() &&
      p1.section === p2.section &&
      p1.id.every((id, idx) => id === p2.id[idx]) &&
      p1.name.every((name, idx) => name === p2.name[idx])
    );
  }

  constructor(
    section: SectorType,
    ifc: MbsProductHttp,
    runtimes?: runtimeInMonths,
    heatPumpTariffId?: number
  ) {
    this.section = section;
    if (!ifc) {
      return;
    }

    this.apiIfc = ifc.priceInformation;
    this.isSufficientInformationProvided = ifc.isSufficientInformationProvided;

    if (ifc.priceInformation && ifc.priceInformation.length > 0) {
      ifc.priceInformation.forEach((priceInfo, runtimeIdx) => {
        /* Each priceInformation array entry is associated with single runtime */
        this.runtimesInMonths.push(priceInfo.tariff.runtimeInMonth);
        /* Fill data pre runtime */
        this.name[runtimeIdx] = priceInfo.tariff.nameExtern;
        this.id[runtimeIdx] = priceInfo.tariff.id;
        this.priceGuarantee[runtimeIdx] = priceInfo.tariff.priceGuarantyFor;
        this.priceGuaranteeEnd[runtimeIdx] = moment(
          +priceInfo.tariff.priceGuarantyEnd
        ).toDate();
        this.prices[runtimeIdx] = {
          runtime: this.runtimesInMonths[runtimeIdx],
          monthly: priceInfo.priceCalculation.totalCostPerMonth,
          rounded: Math.ceil(priceInfo.priceCalculation.totalCostPerMonth),
          yearly: priceInfo.priceCalculation.totalCostPerYear,
          yearlyFirstYear:
            priceInfo.priceCalculation.totalCostPerYearInFirstYear,
          yearlyNet: priceInfo.priceCalculation.totalCostPerYearNet,
          yearlyNetFirstYear:
            priceInfo.priceCalculation.totalCostPerYearInFirstYearNet,
        };
        this.grundpreis[runtimeIdx] = priceInfo.tariff.baseFeeGross;
        this.grundpreisNet[runtimeIdx] = priceInfo.tariff.baseFeeNet;
        this.arbeitspreis[runtimeIdx] = priceInfo.tariff.energyPriceGross;
        this.netpreis[runtimeIdx] = priceInfo.tariff.energyPriceNet;
        this.totalCostPerMonthNet[runtimeIdx] =
          priceInfo.priceCalculation.totalCostPerMonthNet;
        this.buildProductFeatures(priceInfo, runtimeIdx);
      });
    }

    if (heatPumpTariffId) {
      this.setRuntimeByTariffId(
        this.id.findIndex((x) => x === heatPumpTariffId)
      );
    } else {
      runtimes ? this.setActiveRuntime(runtimes) : this.setRuntimeByTariffId();
    }
  }

  getProductName() {
    return this.section !== SectorType.HEATPUMP
      ? this.name[this.activeRuntimeIndex]
      : 'L-Strom.wärme';
  }

  getProductId() {
    return this.id[this.activeRuntimeIndex];
  }

  getBonusExistenceByName(bonusName: string): boolean {
    return this.getFeatures().some((f) => {
      return f.name.toLocaleLowerCase() === bonusName.toLocaleLowerCase();
    });
  }

  getBonusValueByName(bonusName: string): string {
    return this.getFeatures()
      .find((f) => f.name.toLocaleLowerCase() === bonusName.toLocaleLowerCase())
      ?.value.toString().replace(".", ",");
  }

  getBonusNetValueByName(bonusName: string): string {
    return this.getFeatures()
      .find((f) => f.name.toLocaleLowerCase() === bonusName.toLocaleLowerCase())
      ?.valueNet.toString().replace(".", ",");
  }

  public monthlyPaymentsWithBonuses(isPrivateCustomer: boolean): number {
    const productWithBonus = this.getBonusByName(NEW_CUSTOMER_BONUS);
    if (!productWithBonus) return;
    const monthlyPayment = isPrivateCustomer ? this.getPriceMonthly() : this.getPriceMonthlyNet();
    const customerBonusValue = isPrivateCustomer ? productWithBonus.value : productWithBonus.valueNet;
    return Number((monthlyPayment - +customerBonusValue / 12).toFixed(2));
  }

  public getContractBonuses() {
    return this.apiIfc[this.activeRuntimeIndex]?.tariff?.boni;
  }

  private buildProductFeatures(
    priceInfo: MbsPriceInformationDtoInterface,
    runtimeIdx: number
  ) {
    if (!this.features[runtimeIdx]) {
      this.features[runtimeIdx] = [];
    }
    const ft = priceInfo.tariff.boni.map<ProductBonus>((bonus) => {
      return {
        boniOriginal: bonus,
        name: bonus.nameExternal,
        type: 'valueBonus',
        replacement: 'value',
        value: bonus.value,
        valueNet: bonus.valueNet,
      };
    });
    this.features[runtimeIdx].push(...ft);
    this.features[runtimeIdx].push(...this.buildBonuses());
  }

  setActiveRuntime(n: runtimeInMonths, indexByTariffId?: number) {
    this.activeRuntimeMonths = n;
    this.setActiveRuntimeIndex(indexByTariffId);
  }

  private setActiveRuntimeIndex(activeIndexByTariffId?: number) {
    this.activeRuntimeIndex =
      activeIndexByTariffId ||
      this.getDataIndexByRuntime(this.activeRuntimeMonths);
  }

  getActiveRuntimeIndex() {
    return this.activeRuntimeIndex;
  }

  private buildBonuses(): ProductBonus[] {
    return HardcodedBonuses.map((b) => {
      const bc = { ...b };
      if (bc.type === 'ekoEnergyBonus' && this.section === 'GAS') {
        bc.name = '100 % Ökogas';
        return bc;
      } else {
        return bc;
      }
    });
  }

  getActiveRuntimeInMonths(): runtimeInMonths {
    return this.activeRuntimeMonths;
  }

  getDataIndexByRuntime(runtime: runtimeInMonths): number {
    return this.runtimesInMonths.findIndex((rt) => rt === runtime);
  }

  getPriceGuarantee() {
    if (this.isRegioProduct()) {
      return this.getPriceGuaranteeEnd();
    } else {
      return this.priceGuarantee[this.activeRuntimeIndex];
    }
  }

  getFeatures() {
    return this.features[this.activeRuntimeIndex];
  }

  getPriceMonthly(rounded = true) {
    try {
      return rounded
        ? this.prices[this.activeRuntimeIndex].rounded
        : this.prices[this.activeRuntimeIndex].monthly;
    } catch (e) {
      return rounded ? this.prices[0].rounded : this.prices[0].monthly;
    }
  }

  getPriceYearly() {
    return this.prices[this.activeRuntimeIndex].yearly;
  }

  getBaseFeeGross() {
    return this.grundpreis[this.activeRuntimeIndex];
  }

  getBaseFeeNet() {
    return this.grundpreisNet[this.activeRuntimeIndex];
  }

  getEnergyPriceGross() {
    return this.arbeitspreis[this.activeRuntimeIndex];
  }

  getEnergyPriceNet() {
    return this.netpreis[this.activeRuntimeIndex];
  }

  getPriceMonthlyNet() {
    return this.totalCostPerMonthNet[this.activeRuntimeIndex];
  }

  getCostPerYearWithBonuses() {
    return this.prices[this.activeRuntimeIndex].yearlyFirstYear;
  }

  getCostPerYearNetWithBonuses() {
    return this.prices[this.activeRuntimeIndex].yearlyNetFirstYear;
  }

  getCostPerYearNet() {
    return this.prices[this.activeRuntimeIndex].yearlyNet;
  }

  getPriceGuaranteeEnd(): string {
    return moment(this.priceGuaranteeEnd[this.activeRuntimeIndex]).format(
      'DD.MM.YYYY'
    );
  }

  getBonusByName(name: string): ProductBonus {
    const idx = this.features[
      this.getDataIndexByRuntime(this.getActiveRuntimeInMonths())
    ].findIndex((feat) => {
      return feat.name.toLowerCase().includes(name.toLowerCase());
    });
    if (idx !== -1) {
      return this.features[
        this.getDataIndexByRuntime(this.getActiveRuntimeInMonths())
      ][idx];
    } else {
      return null;
    }
  }

  getActiveRuntime(): runtimeInMonths {
    return this.activeRuntimeMonths;
  }

  private getIdxOfObjectstromProduct(): number {
    return this.apiIfc.findIndex(
      (v: MbsPriceInformationDtoInterface) =>
        v.tariff.id === OBJECTSTROM_PRODUCT_ID
    );
  }

  private setRuntimeByTariffId(indexByTariffId?: number): void {
    const idx = indexByTariffId || this.getIdxOfObjectstromProduct();

    if (idx !== -1) {
      this.setActiveRuntime(
        this.apiIfc[idx].tariff.runtimeInMonth,
        indexByTariffId
      );
    } else {
      this.setActiveRuntime(this.apiIfc[0].tariff.runtimeInMonth);
    }
  }

  isRegioProduct(): boolean {
    return REGIO_IDS.some((rid) => rid === this.getProductId());
  }

  isHeatPumpProduct(): boolean {
    return HEATPUMP_IDS.some((tariffId) => this.getProductId() === tariffId);
  }
}
