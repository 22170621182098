import { Moment } from 'moment';
import * as moment from 'moment';
import { sha256 } from 'js-sha256';
import {
  OrderPayloadIfc,
  ApiPayloadCustomer,
  ApiPayloadBankAccountOwner,
  AddressData,
} from './payload.mbs.types';
import { StepOnePersonalDataIfc } from './step1.mbs.types';
import {
  StepTwoAddressDataIfc,
  RESIDENCE_TIME,
  LongResidenceIfc,
  CONTRACT_ENDING_TYPE,
  CURRENT_CONTRACT_PORTAL_END_DATE_TYPE,
  ShortResidenceIfc,
  LongDynamicTariffIfc,
  ShortDynamicTariffIfc,
} from './step2.mbs.types';
import { PAYMENT_METHOD } from './step3.mbs.types';
import { SummaryDataIfc } from './transition.msb.types';
import { Injectable } from '@angular/core';
import { GeneratePayloadInterface } from '../interface';
import { CustomerTypeEnum } from '../enum';
import {
  MbsLegalForm,
  MbsOldSupplierCancellationState,
  MbsSupplyChangeCause,
  SectorType,
} from '@ekso/ekso-types';

export const DateISOFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const DateSimpleFormat = 'YYYY-MM-DD';
export const DateViewFormat = 'DD.MM.YYYY';
export const legalFormDefault = MbsLegalForm.PRIVATE;

@Injectable({
  providedIn: 'root',
})
export class ProductOrderPayloadHelper {
  // tslint:disable-next-line: variable-name
  protected customizePld(currentPld: OrderPayloadIfc): OrderPayloadIfc {
    currentPld.businessConditionsAccepted = true;
    return currentPld;
  }

  private convertToMoment(dateString: string): moment.Moment {
    return moment(dateString, 'DD.MM.YYYY');
  }

  public getNetworkDateISOFormat(m: Moment | string): string {
    if (moment.isMoment(m)) {
      return m.format(DateISOFormat);
    } else {
      return moment(m, 'DD-MM-YYYY').format(DateISOFormat);
    }
  }

  public getNetworkDateSimpleFormat(m: Moment): string {
    if (moment.isMoment(m)) {
      return m.format(DateSimpleFormat);
    } else {
      throw new Error('Incorrect Date format');
    }
  }

  public getViewDateSimpleFormat(m: Moment): string {
    if (moment.isMoment(m)) {
      return m.format(DateViewFormat);
    } else {
      throw new Error('Incorrect Date format');
    }
  }

  public generatePayload(details: GeneratePayloadInterface): OrderPayloadIfc {
    const ret = {} as OrderPayloadIfc;

    if (details.stepThreeData.paymentSelection === PAYMENT_METHOD.SEPA) {
      this.handleBankData(ret, details);
    }

    ret.customer = this.payloadCustomerGeneration(
      details.stepOneData,
      details.summaryData,
      details.businessParterNumber
    );

    ret.deliveryPointAddress = this.getDeliveryAddress(details.stepTwoData);

    ret.consumptionPrognosisKwh = '' + details.searchValues.consumption;

    ret.deliveryPointMeteringDeviceNumber = details.stepTwoData.meterNumber
      ? details.stepTwoData.meterNumber
      : undefined;

    ret.marketLocation = details.stepTwoData.marketLocation
      ? details.stepTwoData.marketLocation
      : undefined;

    ret.section = details.currentSection;
    ret.sectorType = details.searchValues.sectorType;

    if (details.searchValues.sectorType === SectorType.DYNAMIC) {
      this.handleDynamicSector(ret, details);
    } else {
      this.handleStandardSector(ret, details);
    }

    ret.contractDate = this.getNetworkDateSimpleFormat(moment());
    ret.communicationType = details.stepOneData.emailCommunication;
    ret.email = details.stepOneData.email || undefined;

    return this.customizePld(ret);
  }

  private handleBankData(
    payload: OrderPayloadIfc,
    details: GeneratePayloadInterface
  ) {
    payload.bankAccountIban = details.stepThreeData.paymentDetails.iban.replace(
      /\s/g,
      ''
    );
    payload.bankAccountOwner = this.payloadBankOwnerData(
      details.stepOneData,
      details.summaryData
    );
    payload.bankAccountOwnerName = this.payloadBankOwnerName(
      details.searchValues.customerType,
      payload.bankAccountOwner
    );
    payload.bankAccountOwner = undefined;
  }

  private handleDynamicSector(
    payload: OrderPayloadIfc,
    details: GeneratePayloadInterface
  ) {
    const moveInData = details.stepTwoData.moveIn as
      | LongDynamicTariffIfc
      | ShortDynamicTariffIfc;
    const convertedSupplierOldCancellationDate = this.convertToMoment(
      moveInData.supplierOldCancellationDate
    );
    payload.supplyChangeCause = MbsSupplyChangeCause.supplierChange;
    payload.supplyBeginDate = this.getNetworkDateISOFormat(
      moveInData.moveInDate
    );

    switch (moveInData.currentContractEndingType) {
      case CONTRACT_ENDING_TYPE.SELF_MADE:
      case CONTRACT_ENDING_TYPE.SWL_CUSTOMER: {
        payload.oldSupplierCancellationState =
          MbsOldSupplierCancellationState.oldSupplierCanceled;
        payload.supplierOldCancellationDate = this.getNetworkDateISOFormat(
          convertedSupplierOldCancellationDate
        );
        break;
      }
      case CONTRACT_ENDING_TYPE.BY_PORTAL: {
        payload.oldSupplier = (
          details.stepTwoData.moveIn as LongResidenceIfc
        ).currentProvider;
        payload.oldSupplierCancellationState =
          MbsOldSupplierCancellationState.oldSupplierNotCanceled;
        payload.supplierOldCancellationDate = this.getNetworkDateISOFormat(
          convertedSupplierOldCancellationDate
        );
        break;
      }
    }
  }

  private handleStandardSector(
    payload: OrderPayloadIfc,
    details: GeneratePayloadInterface
  ) {
    if (
      details.stepTwoData.residencePeriodSelection ===
      RESIDENCE_TIME.LONGER_THAN_6_WKS
    ) {
      this.handleLongerThan6Weeks(payload, details);
    } else if (
      details.stepTwoData.residencePeriodSelection ===
      RESIDENCE_TIME.SHORTER_THAN_6_WKS
    ) {
      this.handleShorterThan6Weeks(payload, details);
    }

    payload.tarifId =
      details.product.apiIfc[details.product.getActiveRuntimeIndex()].tariff.id;
    payload.tarifName =
      details.product.apiIfc[
        details.product.getActiveRuntimeIndex()
      ].tariff.nameExtern;

    if (
      details.stepTwoData.otherInvoiceAddressEnabled &&
      details.stepTwoData.otherInvoiceAddress
    ) {
      payload.deviatingCustomer = {
        firstname: details.stepTwoData.otherInvoiceAddress.name,
        lastname: details.stepTwoData.otherInvoiceAddress.surname,
        legalForm: legalFormDefault,
      };

      payload.deviatingCustomerAddress = {
        city: details.stepTwoData.otherInvoiceAddress.city,
        postalCode: details.stepTwoData.otherInvoiceAddress.postcode,
        street: details.stepTwoData.otherInvoiceAddress.street,
        housenumber: details.stepTwoData.otherInvoiceAddress.houseNumber,
      };
    }

    payload.addOns = {
      paymentType: details.stepThreeData.paymentSelection,
      residenceTime: details.stepTwoData.residencePeriodSelection,
      contractEndingState:
        details.stepTwoData.residencePeriodSelection ===
        RESIDENCE_TIME.LONGER_THAN_6_WKS
          ? (details.stepTwoData.moveIn as LongResidenceIfc)
              .currentContractEndingType
          : undefined,
      contractEndingDate:
        details.stepTwoData.residencePeriodSelection ===
        RESIDENCE_TIME.LONGER_THAN_6_WKS
          ? (details.stepTwoData.moveIn as LongResidenceIfc)
              .currentContractEndingMethod
          : undefined,
      allDataProvided: details.dataCompleteness,
      section: details.currentSection,
      acquisitionId: sha256(
        payload.customer.email ||
          '' +
            payload.customer.firstname +
            payload.customer.lastname +
            'Brillant'
      ),
    };
  }

  private handleLongerThan6Weeks(
    payload: OrderPayloadIfc,
    details: GeneratePayloadInterface
  ) {
    payload.supplyChangeCause = MbsSupplyChangeCause.supplierChange;

    switch (
      (details.stepTwoData.moveIn as LongResidenceIfc).currentContractEndingType
    ) {
      case CONTRACT_ENDING_TYPE.BY_PORTAL: {
        payload.oldSupplier = (
          details.stepTwoData.moveIn as LongResidenceIfc
        ).currentProvider;
        payload.oldSupplierCancellationState =
          MbsOldSupplierCancellationState.oldSupplierNotCanceled;
        switch (
          (details.stepTwoData.moveIn as LongResidenceIfc)
            .currentContractEndingMethod
        ) {
          case CURRENT_CONTRACT_PORTAL_END_DATE_TYPE.ON_SELECTED_DATE: {
            payload.supplierOldCancellationDate = this.getNetworkDateISOFormat(
              (details.stepTwoData.moveIn as LongResidenceIfc).portalEndDate
            );
            payload.supplyBeginDate = this.getNetworkDateISOFormat(
              (details.stepTwoData.moveIn as LongResidenceIfc).portalEndDate
                .clone()
                .add(1, 'day')
            );
            break;
          }
          case CURRENT_CONTRACT_PORTAL_END_DATE_TYPE.ASAP: {
            payload.supplierOldCancellationDate = undefined;
            payload.supplyBeginDate = undefined;
            break;
          }
        }
        break;
      }
      case CONTRACT_ENDING_TYPE.SELF_MADE:
      case CONTRACT_ENDING_TYPE.SWL_CUSTOMER: {
        payload.oldSupplierCancellationState =
          MbsOldSupplierCancellationState.oldSupplierCanceled;
        payload.supplierOldCancellationDate = this.getNetworkDateISOFormat(
          (details.stepTwoData.moveIn as LongResidenceIfc).selfEndDate
        );
        payload.supplyBeginDate = this.getNetworkDateISOFormat(
          (details.stepTwoData.moveIn as LongResidenceIfc).selfEndDate
            .clone()
            .add(1, 'day')
        );
        break;
      }
    }
  }

  private handleShorterThan6Weeks(
    payload: OrderPayloadIfc,
    details: GeneratePayloadInterface
  ) {
    payload.supplyChangeCause = MbsSupplyChangeCause.move;
    payload.supplyBeginDate = this.getNetworkDateISOFormat(
      (details.stepTwoData.moveIn as ShortResidenceIfc).moveInDate
    );
    /* There is no previous supplier, explicitly wipe it out */
    payload.supplierOldCancellationDate = undefined;
    payload.oldSupplier = undefined;
    payload.oldSupplierCancellationState = undefined;
  }

  private payloadCustomerGeneration(
    stepOneData: StepOnePersonalDataIfc,
    summaryData: SummaryDataIfc,
    businessParterNumber: string
  ): ApiPayloadCustomer {
    return {
      firstname: stepOneData.name || undefined,
      lastname: stepOneData.surname || undefined,
      company: stepOneData.company || undefined,
      nameaddition: stepOneData.nameaddition || undefined,
      email: stepOneData.email || undefined,
      password: stepOneData.password || undefined,
      emailCommunication: {
        channelAddress: stepOneData.email,
        optInDate: summaryData.mailOptin
          ? this.getNetworkDateSimpleFormat(moment())
          : undefined,
        optInOriginalWording: summaryData.mailOptin
          ? 'Optin mail text'
          : undefined,
      },
      phoneNumber: stepOneData.phone,
      phoneCommunication: {
        channelAddress: stepOneData.phone,
        optInDate: summaryData.mailOptin
          ? this.getNetworkDateSimpleFormat(moment())
          : undefined,
        optInOriginalWording: summaryData.mailOptin
          ? 'Optin phone text'
          : undefined,
      },
      // salutation: '' this.generateSalutationFromGender(stepOneData.gender),
      legalForm: stepOneData.legalForm || legalFormDefault,
      dateOfBirth: stepOneData.birthDate
        ? this.getNetworkDateISOFormat(stepOneData.birthDate)
        : undefined,
      /* YYYY-MM-DD  YYYY-MM-DDTHH:MM:ss.mmmmm */
      businessPartnerNumber: businessParterNumber,
    };
  }

  private payloadBankOwnerData(
    stepOneData: StepOnePersonalDataIfc,
    summaryData: SummaryDataIfc
  ): ApiPayloadBankAccountOwner {
    const ret: ApiPayloadBankAccountOwner = {} as ApiPayloadBankAccountOwner;
    ret.firstname = stepOneData.name;
    ret.lastname = stepOneData.surname;
    ret.company = stepOneData.company;
    ret.email = stepOneData.email;
    ret.nameaddition = stepOneData.nameaddition;
    ret.emailCommunication = {
      channelAddress: stepOneData.email,
      optInDate: summaryData.mailOptin
        ? this.getNetworkDateSimpleFormat(moment())
        : undefined,
      optInOriginalWording: '',
    };
    return ret;
  }

  private payloadBankOwnerName(
    customerType: string,
    bankAccountOwner: ApiPayloadBankAccountOwner
  ): string {
    if (customerType === CustomerTypeEnum.PRIVATE) {
      return `${bankAccountOwner.firstname} ${bankAccountOwner.lastname}`;
    } else {
      return bankAccountOwner.nameaddition
        ? `${bankAccountOwner.company} ${bankAccountOwner.nameaddition}`
        : `${bankAccountOwner.company}`;
    }
  }

  private getDeliveryAddress(stepTwoData: StepTwoAddressDataIfc): AddressData {
    return {
      city: stepTwoData.city,
      housenumber: stepTwoData.houseNumber,
      postalCode: stepTwoData.postcode,
      street: stepTwoData.street,
    };
  }

  public getSupplyBeginDateFromFormData(stepTwoData: StepTwoAddressDataIfc) {
    if (
      stepTwoData.residencePeriodSelection === RESIDENCE_TIME.LONGER_THAN_6_WKS
    ) {
      switch (
        (stepTwoData.moveIn as LongResidenceIfc).currentContractEndingType
      ) {
        case CONTRACT_ENDING_TYPE.BY_PORTAL: {
          switch (
            (stepTwoData.moveIn as LongResidenceIfc).currentContractEndingMethod
          ) {
            case CURRENT_CONTRACT_PORTAL_END_DATE_TYPE.ON_SELECTED_DATE: {
              return this.getViewDateSimpleFormat(
                (stepTwoData.moveIn as LongResidenceIfc).portalEndDate
                  .clone()
                  .add(1, 'day')
              );
            }
            case CURRENT_CONTRACT_PORTAL_END_DATE_TYPE.ASAP: {
              return undefined;
            }
          }
          break;
        }
        case CONTRACT_ENDING_TYPE.SELF_MADE:
        case CONTRACT_ENDING_TYPE.SWL_CUSTOMER: {
          return this.getViewDateSimpleFormat(
            (stepTwoData.moveIn as LongResidenceIfc).selfEndDate
              .clone()  
              .add(1, 'day')
          );
        }
      }
    } else if (
      stepTwoData.residencePeriodSelection === RESIDENCE_TIME.SHORTER_THAN_6_WKS
    ) {
      return this.getViewDateSimpleFormat(
        (stepTwoData.moveIn as ShortResidenceIfc).moveInDate.clone()
      );
    }
  }
}
