import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';

import {
  OrderPayloadIfc,
  SECTION_ENUM,
} from '../../../_models/mbs/payload.mbs.types';
import { ApiProvidersIfc } from '../../../_models/mbs/step2.mbs.types';
import { MbsProductHttp } from '../../../_models/mbsProduct.interface';
import { ApiIfc } from '../api.mbs.abstract.class';
import { SimplifiedHttpResponse } from '../../../_models/enum';
import { SectorType } from '@ekso/ekso-types';

@Injectable({
  providedIn: 'root',
})
export class HttpApi implements ApiIfc {
  constructor(private http: HttpClient) {}

  checkIfUserCanBeRegistered(
    url: string,
    businessPartnerNumber: string,
    email: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('businessPartnerNumber', businessPartnerNumber)
      .set('email', email);
    const apiUrl = url + '/mbs/user/v1/check';

    return this.http.get<any>(apiUrl, { params });
  }

  getStreets(
    url: string,
    postalCode: string,
    city: string
  ): Observable<string[]> {
    const p = new HttpParams()
      .append('postalCode', postalCode)
      .append('city', city);
    const apiUrl = `${url}/business-partner/get-streets`;
    return this.http.get<string[]>(apiUrl, { params: p }).pipe(
      retry(1),
      catchError((_e) => {
        return of([]);
      })
    );
  }

  finishAcquisition(
    url: string,
    pld: OrderPayloadIfc,
    queryParams?: { [key: string]: string }
  ): Observable<SimplifiedHttpResponse> {
    let params = new HttpParams();
    if (queryParams) {
      Object.keys(queryParams).forEach((key) => {
        params = params.set(key, queryParams[key]);
      });
    }
    const apiUrl = `${url}/contracts/conclude`;
    return this.http.post(apiUrl, pld, { responseType: 'text', params }).pipe(
      map(() => {
        return SimplifiedHttpResponse.SUCCESS;
      }),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case HttpStatusCode.PreconditionFailed:
            return of(SimplifiedHttpResponse.CREDIT_RATING_FAILED);
          case HttpStatusCode.RequestTimeout:
            return of(SimplifiedHttpResponse.TIME_OUT);
          default:
            return of(SimplifiedHttpResponse.FAILURE);
        }
      })
    );
  }

  validatePostalCode(url: string, postalCode: string): Observable<string[]> {
    return this.getCities(url, postalCode);
  }

  private getCities(url: string, postalCode: string): Observable<string[]> {
    if (!postalCode) {
      return of([]);
    }
    const params = new HttpParams().append('postalCode', postalCode);
    const apiUrl = `${url}/business-partner/get-cities`;
    return this.http.get<string[]>(apiUrl, { params }).pipe(
      catchError((e) => {
        throw e.status === 408
          ? SimplifiedHttpResponse.TIME_OUT
          : SimplifiedHttpResponse.FAILURE;
      })
    );
  }

  getProviders(
    url: string,
    section: SECTION_ENUM = SECTION_ENUM.STROM
  ): Observable<ApiProvidersIfc[]> {
    const p = new HttpParams().append('section', section);
    const apiUrl = `${url}/business-partner/get-suppliers`;
    return this.http.get<ApiProvidersIfc[]>(apiUrl, { params: p }).pipe(
      catchError(() => {
        return of([]);
      })
    );
  }

  getProductByBasicInfo(
    _url: string,
    _postalCode: string,
    _demand: string,
    _section: SECTION_ENUM,
    _isPrivate: boolean,
    _sector: SectorType,
    date: string
  ): Observable<MbsProductHttp> {
    const url = `${_url}/products/search`;
    const productSearchRequestDto = {
      address: {
        postalCode: _postalCode,
      },
      consumption: parseInt(_demand, 10),
      section: _section,
      isPrivateCustomer: _isPrivate,
      sector: _sector,
      moveInDate: date,
    };

    return this.http.post<MbsProductHttp>(url, productSearchRequestDto);
  }

  getProduct(
    _url: string,
    _postalCode: string,
    _demand: string,
    _city: string,
    _street: string,
    _houseNo: string,
    _section: SECTION_ENUM,
    _isPrivate: boolean,
    _sector: SectorType,
    date: string
  ): Observable<MbsProductHttp> {
    const url = `${_url}/products/search`;

    const productSearchRequestDto = {
      address: {
        postalCode: _postalCode,
        city: _city,
        street: _street,
        housenumber: _houseNo,
      },
      consumption: parseInt(_demand, 10),
      section: _section,
      isPrivateCustomer: _isPrivate,
      sector: _sector,
      moveInDate: date,
    };
    return this.http.post<MbsProductHttp>(url, productSearchRequestDto);
  }
}
