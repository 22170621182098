import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LswOption } from '@ui/lsw-lib';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  isMobile: boolean;

  constructor(
    private translateService: TranslateService,
    private deviceService: DeviceDetectorService) { }

  translateViewValues(options: LswOption[]): LswOption[] {
    options.forEach((option) => {
      option.viewValue = this.translateService.instant(option.viewValue);
    });
    return options;
  }

  public checkDevice(): boolean {
    this.isMobile = this.deviceService.isMobile() ? true : false;
    return this.isMobile;
  }
}
