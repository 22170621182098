
export const ObjectstromSet: string[] = [
  '04277'
];

export const BLOCK_OTHER_MBS_WHEN_OBJECTSTROM_PRESENT = true;

export const OBJECTSTROM_PRODUCT_ID = 128;

export const HEATPUMP_BUSINESS_ADJUSTABLE_TARIFF_ID = 415;
export const HEATPUMP_BUSINESS_NOT_ADJUSTABLE_TARIFF_ID = 414;
export const HEATPUMP_PRIVATE_ADJUSTABLE_TARIFF_ID = 413;
export const HEATPUMP_PRIVATE_NOT_ADJUSTABLE_TARIFF_ID = 412;

export const HEATPUMP_IDS = [
  HEATPUMP_BUSINESS_ADJUSTABLE_TARIFF_ID ,
  HEATPUMP_BUSINESS_NOT_ADJUSTABLE_TARIFF_ID ,
  HEATPUMP_PRIVATE_ADJUSTABLE_TARIFF_ID ,
  HEATPUMP_PRIVATE_NOT_ADJUSTABLE_TARIFF_ID
];

export const REGIO_IDS = [
  417, 418, 419, 420
];

export const DYNAMIC_IDS = [
  250, 251, 252, 253
];

export const HEATPUMP_PRODUCTS = {
  PRIVATE: {
    true: {
      tariffId: HEATPUMP_PRIVATE_ADJUSTABLE_TARIFF_ID
    },
    false: {
      tariffId: HEATPUMP_PRIVATE_NOT_ADJUSTABLE_TARIFF_ID
    }
  },
  BUSINESS: {
    true: {
      tariffId: HEATPUMP_BUSINESS_ADJUSTABLE_TARIFF_ID
    },
    false: {
      tariffId: HEATPUMP_BUSINESS_NOT_ADJUSTABLE_TARIFF_ID
    }
  }
};

export const TaxProduct: {
  electricity: number;
  gas: number;
} = {
  electricity: 19,
  gas: 19 // old 7
};
