import { CustomerTypeEnum, sectorTypeMapping } from './enum';
import { SECTION_ENUM } from './mbs/transition.msb.types';
import { ProductBonus } from './mbsProduct.interface';

export const HardcodedBonuses: ProductBonus[] = [
  {
    type: 'plainTextBonus',
    replacement: 'runtime',
    name: ' Monate Preisstabilität',
  },
  {
    type: 'ekoEnergyBonus',
    name: '100 % Ökostrom',
  },
  {
    type: 'plainTextBonus',
    name: 'Online-Kundenbereich'
  },
];

export const NEW_CUSTOMER_BONUS = 'Neukundenbonus'

export const ProductsTariffMapping = {
  [SECTION_ENUM.STROM]: {
    [CustomerTypeEnum.BUSINESS]: {
      [sectorTypeMapping.POWER]: [
        {
          name: 'L-Strom',
          ids: [418]
        },
        {
          name: 'L-Strom.pur',
          ids: [324]
        }
      ],
      [sectorTypeMapping.HEATPUMP]: [
        {
          name: 'L-Strom.wärme',
          ids: [414]
        },
        {
          name: 'L-Strom.wärme',
          ids: [415]
        },
      ],
    },
    [CustomerTypeEnum.PRIVATE]: {
      [sectorTypeMapping.POWER]: [
        {
          name: 'L-Strom',
          ids: [417]
        },
        {
          name: 'L-Strom.pur',
          ids: [310]
        },
      ],
      [sectorTypeMapping.HEATPUMP]: [
        {
          name: 'L-Strom.wärme',
          ids: [412]
        },
        {
          name: 'L-Strom.wärme',
          ids: [413]
        },
      ],
    },
  },
  [SECTION_ENUM.GAS]: {
    [CustomerTypeEnum.BUSINESS]: {
      [sectorTypeMapping.GAS]: [
        {
          name: 'L-Gas',
          ids: [420]
        },
        {
          name: 'L-Gas.pur',
          ids: [326]
        },
      ]
    },
    [CustomerTypeEnum.PRIVATE]: {
      [sectorTypeMapping.GAS]: [
        {
          name: 'L-Gas',
          ids: [419]
        },
        {
          name: 'L-Gas.pur',
          ids: [318]
        },
      ]
    },
  }
};
