import {
  Product,
  ProductCharacteristic,
  SearchValues,
  ProductsFetchResult,
  QueryLevels,
  QUERY_LEVEL,
  ProductHttp,
  RegioDataSetStreet,
  HeatPumpValues,
} from '../_models/interface';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  customerTypeMapping,
  mapSectorTypetoMbsEnum,
  sectorTypeMapping,
} from '../_models/enum';
import { formatDate } from '@angular/common';
import {
  dataDateFormat,
  dataDateLocal,
  FilteredOutPlusProductsID,
} from '../components/search-form/searchfrom.constants';
import { Observable, of, throwError } from 'rxjs';
import { LswOption } from '@ui/lsw-lib';
import {
  DisableSapProductSearch,
  FallbackDefaultSapProduct,
  productConfig,
  tariffOrder,
  visibleBasicTariffs,
  WhitelistFiltering,
} from '../../../../products/src/app/product-comparison/product-comparison.constants';
import { Injectable } from '@angular/core';
import { MbsProduct } from '../_models/mbsProduct';
import { MbsProductHttp } from '../_models/mbsProduct.interface';
import { concatMap, map, catchError } from 'rxjs/operators';
import { ProductService } from '../services/product.service';
import { ApiMbsService } from '../services/mbs/api.mbs';
import {
  BLOCK_OTHER_MBS_WHEN_OBJECTSTROM_PRESENT,
  HEATPUMP_IDS,
  HEATPUMP_PRODUCTS,
  OBJECTSTROM_PRODUCT_ID,
  REGIO_IDS,
} from '../_models/app.consts';
import { SearchProductConfig } from './app.actions';
import * as moment from 'moment';
import { DateSimpleFormat } from '../_models/mbs/acquisitionHelper';
import { SectorType } from '@ekso/ekso-types';

interface ProductFetchProcessChain<T> {
  results: ProductsFetchResult;
  operatorResult: T;
}

@Injectable()
export class AppService {
  // tslint:disable: variable-name
  constructor(
    private httpClient: HttpClient,
    private productService: ProductService,
    private mbsService: ApiMbsService
  ) {}

  private fetchProduct(apiUrl: string, values): Observable<ProductHttp[]> {
    const url = `${apiUrl}/products`;
    const params = new HttpParams()
      .set('Consumption', values.consumption)
      .set('ConsumptionLt', values.consumptionLt)
      .set('PostCode', values.zipCode)
      .set('Street', values.street)
      .set('DivisionID', sectorTypeMapping[values.sectorType])
      .set('BpKindID', customerTypeMapping[values.customerType])
      .set(
        'StartDate',
        formatDate(values.moveInDate, dataDateFormat, dataDateLocal)
      )
      .set('ProductID', values.productId)
      .set('OnlineP', values.online ? 'X' : '')
      .set('GreenEnergy', values.eco ? 'X' : '')
      .set('UseLt', values.useLt ? 'X' : '')
      .set('Bonus01', 'X')
      .set('Bonus02', 'X');
    return this.httpClient.get<ProductHttp[]>(url, { params });
  }

  public getCitiesMbs(apiUrl: string, zip: string): Observable<string[]> {
    return this.mbsService.validatePostalCode(apiUrl, zip);
  }

  public getStreetsMbs(
    apiUrl: string,
    zip: string,
    city: string
  ): Observable<string[]> {
    return this.mbsService.getStreets(apiUrl, zip, city);
  }

  public getStreets(
    apiUrl: string,
    zip: string,
    city: string
  ): Observable<RegioDataSetStreet[]> {
    const url = `${apiUrl}/business-partner/cities-by-country`;
    const params = new HttpParams({
      fromObject: {
        country: 'DE',
        postCode: zip,
        city,
      },
    });
    const headers = new HttpHeaders().set('loadingIndicator', 'none');
    return this.httpClient.get<RegioDataSetStreet[]>(url, { params, headers });
  }

  private getMbsProduct(
    searchResults: ProductsFetchResult,
    url: string,
    searchValues: SearchValues
  ): Observable<MbsProductHttp | null> {
    let cityObservable: Observable<string>;
    /* City comes from search form - no ambiguity, single value */
    if (searchValues.city) {
      cityObservable = of(searchValues.city);
      /* Just single city was queried at stream opening - no ambiguity */
    } else if (searchResults.cities.length === 1) {
      cityObservable = of(searchResults.cities[0].value);
      /* It's not possible to query with ambiguous city - extended form shall be requested */
    } else {
      cityObservable = of(null);
    }

    return cityObservable.pipe(
      concatMap((_cities) => {
        return this.fetchMbsProduct(url, searchValues);
      }),
      catchError((e) => {
        return throwError(e);
      })
    );
  }

  private handleMbsProducts(
    returnValue: ProductsFetchResult,
    productsResult: MbsProductHttp | null,
    d: SearchValues,
    apiUrl: string,
    _heatPumpValues: HeatPumpValues
  ): Observable<ProductsFetchResult> {
    /* Previous operator does not deliver result - MBS is already done/drained/failed */
    if (productsResult === null) {
      return of(returnValue);
    }
    /* Product was delivered with previous MBS fetch */
    if (MbsProduct.isPayloadAProduct(productsResult)) {
      returnValue.queryLevels.mbs.done = true;
      return of({
        ...returnValue,
        mbsProducts:
          productsResult.priceInformation
            .filter((pi) => {
              if (d.sectorType === SectorType.HEATPUMP) {
                return (
                  HEATPUMP_PRODUCTS[d.customerType][
                    _heatPumpValues.secondQuestion ? 'true' : 'false'
                  ].tariffId === pi.tariff.id
                );
              } else {
                return !HEATPUMP_IDS.some((v) => pi.tariff.id === v);
              }
            })
            .map((v) => {
              return new MbsProduct(
                d.sectorType,
                {
                  expenses: productsResult.expenses,
                  isSufficientInformationProvided:
                    productsResult.isSufficientInformationProvided,
                  priceInformation: [v],
                },
                null,
                _heatPumpValues?.tariffId
              );
            }),
      });
      /* Product was not delivered with previous MBS fetch */
    } else {
      return of(returnValue).pipe(
        concatMap((rv) => {
          let streetsObservable: Observable<LswOption[]>;
          rv.queryLevels.mbs.level += 1;
          if (rv.queryLevels.mbs.level === QUERY_LEVEL.SEARCH_DRAIN) {
            rv.queryLevels.mbs.drain = true;
            return of(returnValue);
          }
          if (
            rv.queryLevels.mbs.level === QUERY_LEVEL.DETAILED_SEARCH &&
            rv.streets.length > 0
          ) {
            streetsObservable = this.getStreetsMbs(
              apiUrl,
              d.zipCode,
              returnValue.cities[0].value
            ).pipe(
              map<string[], LswOption[]>((r) =>
                r.map((s) => ({ value: s, viewValue: s }))
              ),
              map<LswOption[], LswOption[]>((r) => {
                if (r.length === rv.streets.length) {
                  return rv.streets;
                } else {
                  return this.mergeStreetsOptionsFromSources(r, rv.streets);
                }
              })
            );
          } else {
            streetsObservable = this.getStreetsMbs(
              apiUrl,
              d.zipCode,
              returnValue.cities[0].value
            ).pipe(
              map((r) => r.map<LswOption>((s) => ({ viewValue: s, value: s })))
            );
          }
          return streetsObservable.pipe(
            map((streets) => {
              return { ...returnValue, streets };
            })
          );
        })
      );
    }
  }

  private mergeStreetsOptionsFromSources(
    mbsStreets: LswOption[],
    sapStreets: LswOption[]
  ): LswOption[] {
    return mbsStreets.length < sapStreets.length ? sapStreets : mbsStreets;
  }

  private fetchMbsProduct(
    apiUrl: string,
    searchVals: SearchValues
  ): Observable<MbsProductHttp> {
    if (searchVals.city && searchVals.houseNo && searchVals.street) {
      return this.mbsService.getProduct(
        apiUrl,
        searchVals.zipCode,
        searchVals.consumption,
        searchVals.city,
        searchVals.street,
        searchVals.houseNo,
        mapSectorTypetoMbsEnum(searchVals.sectorType),
        searchVals.customerType === 'PRIVATE' ? true : false,
        searchVals.sectorType,
        searchVals.moveInDate
          ? moment(searchVals.moveInDate).format(DateSimpleFormat)
          : moment().format(DateSimpleFormat)
      );
    } else {
      return this.mbsService.getProductByBasicInfo(
        apiUrl,
        searchVals.zipCode,
        searchVals.consumption,
        mapSectorTypetoMbsEnum(searchVals.sectorType),
        searchVals.customerType === 'PRIVATE' ? true : false,
        searchVals.sectorType,
        searchVals.moveInDate
          ? moment(searchVals.moveInDate).format(DateSimpleFormat)
          : moment().format(DateSimpleFormat)
      );
    }
  }

  getStreetsOrProductList(
    apiUrl: string,
    formValues: SearchValues
  ): Observable<object> {
    const url = `${apiUrl}/products/search`;
    const businessPartnerType = customerTypeMapping[formValues.customerType];
    const productSearchRequestDto = {
      address: {
        postalCode: formValues.zipCode,
        street: formValues.street,
      },
      consumption: parseInt(formValues.consumption, 10),
      section:
        sectorTypeMapping[formValues.sectorType] === '01'
          ? 'Electricity'
          : 'Gas',
      isPrivateCustomer: !businessPartnerType || businessPartnerType === '0001',
      moveInDate: formatDate(
        formValues.moveInDate,
        dataDateFormat,
        dataDateLocal
      ),
    };

    return this.httpClient
      .post<MbsProductHttp>(url, productSearchRequestDto)
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  getVisibleProductsInformation(
    apiUrl: string,
    formValues: SearchValues
  ): Observable<any> {
    const url = `${apiUrl}/gridinformationforaddress`;
    const params = new HttpParams()
      .set('PostalCode', formValues.zipCode)
      .set('DivisionID', sectorTypeMapping[formValues.sectorType])
      .set('Street', formValues.street);
    return this.httpClient.get(url, { params });
  }

  getProduct(apiUrl: string, values): Observable<Product | null> {
    return new Observable<Product>((observer) => {
      this.fetchProduct(apiUrl, values).subscribe(
        (res) => {
          let p: Product;
          try {
            if (res[0]) {
              p = this.prepareProduct(res[0]);
              observer.next(p);
            } else {
              observer.next(null);
            }
            observer.complete();
          } catch (e) {
            observer.error(e);
          }
        },
        (e) => {
          observer.error(e);
        }
      );
    });
  }

  public fetchCities(
    fetchResult: ProductsFetchResult,
    apiUrl: string,
    formValues: SearchValues
  ): Observable<ProductsFetchResult> {
    return this.getCitiesMbs(apiUrl, formValues.zipCode).pipe(
      map<string[], LswOption<string, string>[]>((v) => v.map((s) => ({ viewValue: s, value: s }))),
      catchError((_e) => of([] as LswOption[])),
      map((mbsCities) => {
        if (mbsCities) {
          fetchResult.cities = mbsCities;
        }
        return fetchResult;
      }),
    );
  }

  getStreetsOrProducts(
    apiUrl: string,
    formValues: SearchValues,
    queryLevels: QueryLevels,
    heatPumpValues: HeatPumpValues,
    _config: SearchProductConfig
  ): Observable<ProductsFetchResult> {
    const returnValue: ProductsFetchResult = {
      products: [],
      basicSupplyProduct: null,
      streets: [],
      cities: [],
      mbsProducts: [],
      queryLevels: JSON.parse(JSON.stringify(queryLevels)),
    };

    return this.fetchCities(returnValue, apiUrl, formValues).pipe(
      concatMap((fetchResultsWithCities) => {
        if (DisableSapProductSearch) {
          return of({ operatorResult: [], results: fetchResultsWithCities });
        } else {
          return this.getStreetsOrProductList(apiUrl, formValues).pipe(
            map<object | null, ProductFetchProcessChain<object | null>>(
              (sapHttpResponse: object | null) => {
                return {
                  operatorResult: sapHttpResponse,
                  results: fetchResultsWithCities,
                };
              }
            )
          );
        }
      }),
      concatMap((response) => {
        return this.handleSapProductResult(
          response.results,
          response.operatorResult,
          apiUrl,
          formValues
        );
      }),
      catchError((e) => {
        /* All SAP errors lands here */
        returnValue.queryLevels.sap.error = e;
        returnValue.queryLevels.sap.fail = true;
        returnValue.queryLevels.sap.level = QUERY_LEVEL.SEARCH_FAILED;
        return of(returnValue);
      }),
      concatMap((rv: ProductsFetchResult) => {
        return this.getMbsProduct(rv, apiUrl, formValues).pipe(
          map<
            MbsProductHttp | null,
            ProductFetchProcessChain<MbsProductHttp | null>
          >((response: MbsProductHttp | null) => {
            return {
              results: rv,
              operatorResult: response,
            };
          })
        );
      }),
      concatMap((r: ProductFetchProcessChain<MbsProductHttp | null>) => {
        return this.handleMbsProducts(
          r.results,
          r.operatorResult,
          formValues,
          apiUrl,
          heatPumpValues
        );
      }),
      catchError((e) => {
        /* All MBS errors lands here */
        returnValue.queryLevels.mbs.error = e;
        returnValue.queryLevels.mbs.fail = true;
        returnValue.queryLevels.mbs.level = QUERY_LEVEL.SEARCH_FAILED;
        return of(returnValue);
      }),
      map((res: ProductsFetchResult) => {
        return this.handleObjectstromProducts(res);
      }),
      map((res: ProductsFetchResult) => {
        return this.handleFiltering(res, formValues);
      }),
      map((res) => {
        return this.sortRegioProduct(res);
      })
    );
  }

  private handleObjectstromProducts(
    res: ProductsFetchResult
  ): ProductsFetchResult {
    if (
      res.mbsProducts &&
      res.mbsProducts.length >= 1 &&
      res.mbsProducts.some((mbsP) => {
        return mbsP.id.some((pId) => pId === OBJECTSTROM_PRODUCT_ID);
      })
    ) {
      res.products = res.products.filter(
        (p) =>
          p.ProductID === 'STROM_GRUNDVERSORG_HH' ||
          p.ProductID === 'STROM_GRUNDVERSORG_GW' ||
          p.ProductID === 'STROM_BASISVERSORG_GW'
      );
      if (BLOCK_OTHER_MBS_WHEN_OBJECTSTROM_PRESENT) {
        res.mbsProducts = res.mbsProducts.filter((p) =>
          p.id.some(
            (singleId) =>
              singleId === OBJECTSTROM_PRODUCT_ID ||
              REGIO_IDS.includes(singleId)
          )
        );
      }
    }
    return res;
  }

  private sortRegioProduct(res: ProductsFetchResult): ProductsFetchResult {
    console.warn('sorting');
    const idx = res.mbsProducts.findIndex((mbsProd) =>
      mbsProd.apiIfc.some((ifc) =>
        REGIO_IDS.some((rId) => rId === ifc.tariff.id)
      )
    );
    if (idx !== -1) {
      const r = res.mbsProducts.splice(idx, 1);
      res.mbsProducts.push(...r);
    }
    return res;
  }

  private handleFiltering(
    res: ProductsFetchResult,
    query: SearchValues
  ): ProductsFetchResult {
    res.products = res.products.filter((p) => {
      const against =
        FilteredOutPlusProductsID[query.sectorType][query.customerType];
      if (against.length > 0) {
        return against.some((restrictedId) => {
          return restrictedId !== p.ProductID;
        });
      } else {
        return true;
      }
    });
    return res;
  }

  private handleSapProductResult(
    returnValue: ProductsFetchResult,
    productsResult: object,
    apiUrl: string,
    formValues: SearchValues
  ): Observable<ProductsFetchResult> {
    /* Previous operator didn't return HTTP response - don't change anything, just pass by */
    if (productsResult === null) {
      return of(returnValue);
    }
    /* Search suggestions - assign streets */
    if (productsResult[0].SearchSuggestions.results.length > 0) {
      returnValue.streets = this.generateStreetOptions(
        productsResult[0].SearchSuggestions.results
      );
      returnValue.queryLevels.sap.level = QUERY_LEVEL.DETAILED_SEARCH;
      return of(returnValue);
      /* No search suggestion - we got a product or this area does not contain any */
    } else {
      returnValue.products = (
        productsResult[0].Products.results as Product[]
      ).filter((p) => {
        if (WhitelistFiltering) {
          return WhitelistFiltering.some(
            (allowedID) => p.ProductID === allowedID
          );
        } else {
          return true;
        }
      });
      if (returnValue.products.length) {
        return this.getVisibleProductsInformation(apiUrl, formValues).pipe(
          map((gridInfoResult) => {
            try {
              const tmp = this.getFormattedProducts(
                returnValue.products,
                gridInfoResult
              );
              returnValue.products = tmp.products;
              returnValue.basicSupplyProduct = tmp.basicSupplyProduct;
              returnValue.queryLevels.sap.done = true;
              return returnValue;
            } catch (e) {
              throwError(returnValue);
            }
          })
        );
        /* No SAP products at all - this area does not contain SAP product */
      } else {
        returnValue.queryLevels.sap.drain = true;
        returnValue.queryLevels.sap.level = QUERY_LEVEL.SEARCH_DRAIN;
        return of(returnValue);
      }
    }
  }

  private generateStreetOptions(
    streetSuggestionResults
  ): LswOption<string, string>[] {
    return streetSuggestionResults.map(
      (streetSuggestion: { Street: string }) => {
        return {
          value: streetSuggestion.Street,
          viewValue: streetSuggestion.Street,
        };
      }
    );
  }

  getFormattedProducts(products, gridInfo) {
    let preparedProducts: Product[] = [];
    let basicSupplyProduct: Product = null;
    products.map((product) => {
      if (product.BasicSupplyTariff) {
        basicSupplyProduct = product;
      }
      gridInfo.map((gridElement) => {
        if (product.GridID === gridElement.GridID) {
          product.vicinity = gridElement.VicinityGrid;
        }
      });
      if (
        (product.BasicSupplyTariff &&
          visibleBasicTariffs.includes(product.ProductID)) ||
        !product.BasicSupplyTariff
      ) {
        product = this.prepareProduct(product);
        preparedProducts.push(product);
      }
    });
    preparedProducts = this.sortProducts(preparedProducts);
    return { products: preparedProducts, basicSupplyProduct };
  }

  sortProducts(preparedProducts) {
    return preparedProducts.sort((a, b) => {
      return (
        tariffOrder.indexOf(a.ProductID) - tariffOrder.indexOf(b.ProductID)
      );
    });
  }

  prepareProduct(product: ProductHttp): Product {
    try {
      const configPart = productConfig[product.ProductID]
        ? productConfig[product.ProductID]
        : FallbackDefaultSapProduct;
      const preparedProduct = { ...product, ...configPart };
      if (!this.productService.instanceOfProduct(preparedProduct)) {
        throw new Error('Products invalid');
      }
      return preparedProduct;
    } catch (e) {
      throw new Error('Products invalid');
    }
  }

  productCharacteristicsHaveFootnote(products): boolean {
    const descriptionsWithFootnote: ProductCharacteristic[] = [];
    products?.forEach((product) => {
      const descriptionWithFootnote: ProductCharacteristic =
        product.ProductCharacteristics.results.find((char) =>
          char.Characteristic.match(/[\u2070-\u209f\u00b0-\u00be]+/g)
        );
      if (descriptionWithFootnote) {
        descriptionsWithFootnote.push(descriptionWithFootnote);
      }
    });
    return descriptionsWithFootnote.length !== 0;
  }
}
