import { Injectable } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import { formatDate, registerLocaleData } from '@angular/common';
import {
  businessGasConsumptionOptions,
  businessHeatPumpConsumptionOptions,
  businessPowerConsumptionOptions,
  dataDateLocal,
  privateGasConsumptionOptions,
  privateHeatPumpConsumptionOptions,
} from './searchfrom.constants';
import { TranslateService } from '@ngx-translate/core';
import {
  CustomerType,
  SearchFormAppConfig,
} from '../../_models/interface';
import { DateService } from '../../services/date.service';
import { SharedConfiguration } from '../../shared.configuration';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { SectorType } from '@ekso/ekso-types';
registerLocaleData(localeDe, 'de');

@Injectable()
export class SearchFormService {
  sourceValues: string[] = [];
  sourceObservables: Observable<string>[] = [];
  public prefilledText$ = new BehaviorSubject<string>('');
  privatePowerConsumptionOptions = this.getPrivatePowerConsumptionOptions();

  constructor(
    private translateService: TranslateService,
    private dateService: DateService,
    private sharedConfig: SharedConfiguration
  ) {}

  private removeOrFormatMoveInDate(formValues, hasDate, dateFormat) {
    if (hasDate) {
      formValues.moveInDate = formatDate(
        formValues.moveInDate,
        dateFormat,
        dataDateLocal
      );
    } else {
      delete formValues.moveInDate;
    }
    return formValues;
  }

  public shouldTakeOfferViaPlatform(
    customerType: CustomerType,
    consumption: number,
    sector: SectorType
  ): boolean {
    let result = false;
    if (
      customerType === 'BUSINESS' &&
      ((sector === 'GAS' && consumption >= 250000) ||
        (sector === 'POWER' && consumption >= 100000))
    ) {
      result = true;
    }
    return result;
  }

  generatePrefilledValueString(formValues, showDate: boolean): void {
    this.sourceObservables = [];
    this.sourceValues = [];
    formValues = this.removeOrFormatMoveInDate(
      formValues,
      showDate,
      'dd.MM.yyyy'
    );
    if (formValues.street === '') {
      delete formValues.street;
    }

    // because we want return texts in special ordering
    Object.keys(formValues).forEach((key) => {
      const value = formValues[key];
      if (key === 'sectorType') {
        this.sourceObservables.push(this.translateService.get(`SEARCHFORM.SECTORTYPE.${value}`));
      }
    });
    Object.keys(formValues).forEach((key) => {
      const value = formValues[key];
      if (key === 'customerType') {
        this.sourceObservables.push(this.translateService.get(`SEARCHFORM.CUSTOMERTYPE.${value}`));
      }
    });
    Object.keys(formValues).forEach((key) => {
      const value = formValues[key];
      if (key === 'zipCode') {
        this.sourceObservables.push(
          this.translateService.get('SEARCHFORM.ZIPCODE.LABEL')
        );
        this.sourceValues.push(value);
      }
    });
    Object.keys(formValues).forEach((key) => {
      const value = formValues[key];
      if (key === 'street') {
        this.sourceValues.push(value);
      }
    });

    Object.keys(formValues).forEach((key) => {
      const value = formValues[key];
      if (key === 'houseNo') {
        this.sourceValues.push(value);
      }
    });
    
    Object.keys(formValues).forEach((key) => {
      const value = formValues[key];
      if (key === 'consumption') {
        this.sourceObservables.push(
          this.translateService.get('SEARCHFORM.CONSUMPTION.LABEL.BEFORE')
        );
        this.sourceObservables.push(
          this.translateService.get('SEARCHFORM.CONSUMPTION.LABEL')
        );
        this.sourceObservables.push(
          this.translateService.get('SEARCHFORM.CONSUMPTION.LABEL.END')
        );
        this.sourceValues.push(value);
      }
    });

    Object.keys(formValues).forEach((key) => {
      const value = formValues[key];
      if (key === 'moveInDate') {
        this.sourceObservables.push(
          this.translateService.get('SEARCHFORM.MOVEINDATE.LABEL')
        );
        this.sourceValues.push(value);
      }
    });

    forkJoin(this.sourceObservables).subscribe(
      (res) => {
        const moveInDate =
          res.length > 6 ? `, ${res[6]}:&nbsp;${this.sourceValues[3]}` : '';

        this.prefilledText$.next(
          `${res[0]} ${res[1]} <b class="bold"> ${res[2]} ${this.sourceValues[0]}</b> ${this.sourceValues[1]} ${this.sourceValues[2]} ${res[3]} <b class="bold"> ${this.sourceValues[3]} ${res[4]} </b> ${res[5]} ${moveInDate}`
        );
      },
      (error) => console.error(error.message)
    );
  }

  generateInitialSearchFormAppProperties(el): SearchFormAppConfig {
    const { minMoveInDate, maxMoveInDate } =
      this.dateService.getSelectableMoveInDateRange();
    return {
      lang: el.getAttribute('lang') || 'de',
      customerType: el.getAttribute('customerType') || 'PRIVATE',
      sectorType: el.getAttribute('sectorType') || 'POWER',
      showCustomerSelect: JSON.parse(el.getAttribute('showCustomerSelect')),
      showSectorSelect: JSON.parse(el.getAttribute('showSectorSelect')),
      showConsumptionDropdown: JSON.parse(
        el.getAttribute('showConsumptionDropdown')
      ),
      minMoveInDate,
      maxMoveInDate,
      selectedMoveInDate: new Date(),
      bigConsumptionUrl: el.getAttribute('bigConsumptionUrl'),
      hideHeatPump: JSON.parse(el.getAttribute('hideHeatPump')),
      showDynamicPowerTab: JSON.parse(el.getAttribute('showDynamicPowerTab')),
      heatPumpPrivilegeLink: el.getAttribute('heatPumpPrivilegeLink'),
      heatPumpConsumptionLink: el.getAttribute('heatPumpConsumptionLink'),
    };
  }

  getConsumptionOptions() {
    return {
      PRIVATE: {
        GAS: privateGasConsumptionOptions,
        POWER: this.privatePowerConsumptionOptions,
        HEATPUMP: privateHeatPumpConsumptionOptions,
        DYNAMIC: this.privatePowerConsumptionOptions,
      },
      BUSINESS: {
        GAS: businessGasConsumptionOptions,
        POWER: businessPowerConsumptionOptions,
        HEATPUMP: businessHeatPumpConsumptionOptions,
        DYNAMIC: businessPowerConsumptionOptions,
      },
    };
  }

  getPrivatePowerConsumptionOptions() {
    const normalPersonImagePath =
      this.sharedConfig.assetPath + '/common/images/strom_person.png';
    const littlePersonImagePath =
      this.sharedConfig.assetPath + '/common/images/strom_person_klein.png';
    return [
      {
        viewValue: `1.200 kWh <img style="padding-left: 10px;" src="${normalPersonImagePath}" alt="1 Person">`,
        value: '1200',
      },
      {
        viewValue: `1.900 kWh <img style="padding-left: 10px;" src="${normalPersonImagePath}" alt="1 Person">
        <img style="padding-bottom: 4px;" src="${littlePersonImagePath}" alt="1 Kind">`,
        value: '1900',
      },
      {
        viewValue: `2.500 kWh <img style="padding-left: 10px;" src="${normalPersonImagePath}" alt="1 Person">
        <img src="${normalPersonImagePath}" alt="1 Person">
        <img style="padding-bottom: 4px;" src="${littlePersonImagePath}" alt="1 Kind">`,
        value: '2500',
      },
      {
        viewValue: `3.500 kWh <img style="padding-left: 10px;" src="${normalPersonImagePath}" alt="1 Person">
        <img src="${normalPersonImagePath}" alt="1 Person">
        <img src="${normalPersonImagePath}" alt="1 Person">
        <img style="padding-bottom: 4px;" src="${littlePersonImagePath}" alt="1 Kind">`,
        value: '3500',
      },
    ];
  }

  public translateLabel(labelId: string) {
    return this.translateService.instant(labelId);
  }
}
