import { Injectable } from '@angular/core';
import { environment } from 'projects/contract/src/environments/environment';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { MbsCityMocks, CITY_MOCK_TYPE } from '../../../_models/mbs/mocks/mbsCities.mock';
import { MbsProvidersMock } from '../../../_models/mbs/mocks/mbsProviders.mock';
import { MarkranstadtStreetsMock, LutzenStreetsMock, LiepzigStreetsMock } from '../../../_models/mbs/mocks/mbsStreets.mock';
import { SECTION_ENUM, OrderPayloadIfc } from '../../../_models/mbs/payload.mbs.types';
import { ApiProvidersIfc } from '../../../_models/mbs/step2.mbs.types';
import { MbsProductsStromMock } from '../../../_models/mbsMocks';
import { MbsProductHttp } from '../../../_models/mbsProduct.interface';
import { ApiIfc } from '../api.mbs.abstract.class';
import { SimplifiedHttpResponse } from '../../../_models/enum';

  // tslint:disable: variable-name
@Injectable({
  providedIn: 'root',
})

export class NonHttpApiImpl implements ApiIfc {
  getStreets(
    _url: string,
    _postalCode: string,
    city: string
  ): Observable<string[]> {
    let retMock: string[] = [];
    switch (city) {
      case 'Markranstädt':
        retMock = MarkranstadtStreetsMock;
        break;
      case 'Lützen':
        retMock = LutzenStreetsMock;
        break;
      default:
        retMock = LiepzigStreetsMock;
        break;
    }
    return of(retMock);
  }

  // validateIban(iban: string): Observable<ApiIbanValidationResultIfc> {
  //   let retObs: Observable<ApiIbanValidationResultIfc>;
  //   if (iban.includes('11112222')) {
  //     retObs = of({ ...IbanValidationDataMock[IBAN_MOCK_TYPE.INVALID], iban });
  //   } else {
  //     retObs = of({ ...IbanValidationDataMock[IBAN_MOCK_TYPE.VALID], iban });
  //   }
  //   return timer(500).pipe(
  //     switchMap(v => {
  //       return retObs;
  //     }),
  //     delay(environment.delayMockService.enabled ? environment.delayMockService.value : 0)
  //   );
  // }

  finishAcquisition(
    _url: string,
    pld: OrderPayloadIfc,
    queryParams?: { [key: string]: string },
  ): Observable<SimplifiedHttpResponse> {
    console.warn(pld, queryParams);
    return of(SimplifiedHttpResponse.SUCCESS);
  }

  validatePostalCode(_url: string, postalCode: string): Observable<string[]> {
    let baseObs: Observable<string[]>;
    if (postalCode === '11111') {
      baseObs = of(MbsCityMocks[CITY_MOCK_TYPE.EMPTY]);
    } else if (postalCode === '12345') {
      baseObs = of(MbsCityMocks[CITY_MOCK_TYPE.MULTI]);
    } else if (postalCode === '11223') {
      baseObs = of(MbsCityMocks[CITY_MOCK_TYPE.SINGLE_CITY_MULTI_PRODUCT]);
    } else {
      baseObs = of(MbsCityMocks[CITY_MOCK_TYPE.SINGLE]);
    }
    return baseObs;
  }

  getProviders(
    _url: string,
  ): Observable<ApiProvidersIfc[]> {
    return of(MbsProvidersMock).pipe(
      delay(
        environment.delayMockService.enabled
          ? environment.delayMockService.value
          : 0
      ),
    )
  }

  checkIfUserCanBeRegistered(
    _url: string,
    businessPartnerNumber: string,
    email: string
  ): Observable<any> {
    if (businessPartnerNumber === 'B01061739492' && email === 'test@mail.com') {
      return of({
        userExistsInKc: false,
        correctData: true,
      });
    } else if (email === 'existing@mail.com') {
      return of({
        userExistsInKc: true,
        correctData: true,
      });
    } else {
      return of({
        userExistsInKc: false,
        correctData: false,
      });
    }
  }

  getProductByBasicInfo(
    _url: string,
    _postalCode: string,
    _demand: string,
    _section: SECTION_ENUM
  ): Observable<MbsProductHttp> {
    return of(MbsProductsStromMock);
  }

  getProduct(
    _url: string,
    _postalCode: string,
    _demand: string,
    _city: string,
    _street: string,
    _houseNo: string,
    _section: SECTION_ENUM
  ): Observable<MbsProductHttp> {
    return of(MbsProductsStromMock);
  }
}
