import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FeatureTypeIfc } from '../_models/interface';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MbsProductService {
  constructor(private http: HttpClient) {}

  public displayRblProductLink(baseUrl: string): Observable<boolean> {
    const url = `${baseUrl}/feature-flag/get?name=display_rbl_product_link`;

    const headers = new HttpHeaders().set('loadingIndicator', 'none');

    return this.http.get<FeatureTypeIfc>(url, { headers }).pipe(
      map((featureFlag) => featureFlag.enabled),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return of(false);
      })
    );
  }

  public displayMarketingInfo(baseUrl: string): Observable<boolean> {
    const url = `${baseUrl}/feature-flag/get?name=marketing_info`;

    const headers = new HttpHeaders().set('loadingIndicator', 'none');

    return this.http.get<FeatureTypeIfc>(url, { headers }).pipe(
      map((featureFlag) => featureFlag.enabled),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return of(false);
      })
    );
  }
}
